import * as React from 'react';
import { Redirect } from '@reach/router';

import rootPath from '../routes';

interface Props {}

interface State {}

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <Redirect to={ rootPath() } noThrow={ true } />
    );
  }
}
